import axios from 'axios';

const UrlGral = localStorage.getItem("UrlGral")

export class Pedidos {

    getCustomersMedium() {
        return axios.get(UrlGral+'pedidos/')
            .then(res => res.data);
    }

    getCustomersLarge() {
        return axios.get(UrlGral+'pedidos/')
                .then(res => res.data);
    }
}

export class DetallePedido {

    getDetalle() {
        return axios.get(UrlGral+'pedidos/')
            .then(res => res.data);
    }
}