import axios from 'axios';
const UrlGral = localStorage.getItem("UrlGral")

export class Pedidos {    
    getCustomersLarge() {
        return axios.get(UrlGral+"pedidos/?llave="+localStorage.getItem('llave')+"&id_usuario="+localStorage.getItem('user_id')).then(res => res.data);                
    }

    getCustomersLargeAdmin() {
        return axios.get(UrlGral+"pedidos/?admin=1&llave="+localStorage.getItem('llave')+"&id_usuario="+localStorage.getItem('user_id')).then(res => res.data);                
    }
    
}