import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Redirect } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';


export const MarcasComponente = () => {

    let emptyMarca = {
        id: '',
        img: '',
        nombre: ''
    };

    const UrlGral = localStorage.getItem("UrlGral")


    const [marca, setMarca] = useState(emptyMarca);
    const [marcas, setMarcas] = useState(null);
    const [selectedCustomers1, setSelectedCustomers1] = useState(null);
    const [globalFilter1, setGlobalFilter1] = useState('');
    const dt = useRef(null);
    const [redirect, setRedirect] = useState(null);
    const toast = useRef();

    
    useEffect(() => {
        class MarcasUser{
            gerMarcaUserUrl() {
                return axios.get(UrlGral+'marcas/?llave='+localStorage.getItem('llave')).then(res => res.data);
            }
        }
        const marca = new MarcasUser();
        marca.gerMarcaUserUrl().then(data => setMarcas(data));
        if(!localStorage.getItem('user_id')){
        setRedirect('/login');
        }
    }, []);

    const idMarca = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.id}
            </>
        );
    }

    const imgMarca = (rowData) => {
        return (
            <>
                <div className="contenedorimgmarca">
                    <img src={`assets/img/marcas/${rowData.img}`} alt={rowData.img} className="brand-image" />
                </div>
            </>
        );
    }

    const nombreClienteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    }

    const urlMarcaBodyTemplate = (rowData) => {
        if(rowData.url == ""){
            var url = rowData.url
        } else {
            var url = rowData.url
        } 
        return (
            <>
                <a href={url} target="_blank">Ver catálogo de {rowData.nombre}</a>
            </>
        );
    }

    const customer1TableHeader = (
        <div className="table-header">
            <div className="p-grid">
                <div className="p-col-12">
                    <span className="p-input-icon-left">                
                        <i className="pi pi-search" />
                        <InputText onChange={(e) => setGlobalFilter1(e.target.value)} placeholder="Buscar" />
                    </span>
                </div>
            </div>
        </div>
    );



    if(redirect){
        return <Redirect to={redirect}/>
    }

    
    return (
        <div className="p-grid crud-demo">
            <Toast ref={toast} />
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12">
                        <DataTable ref={dt} value={marcas} selection={selectedCustomers1} onSelectionChange={(e) => setSelectedCustomers1(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"                            
                            globalFilter={globalFilter1} emptyMessage="No products found." header={customer1TableHeader}>   
                            <Column className="datoproducto imgbrandcolumna" field="img" header="Img Marca" sortable body={imgMarca}></Column>
                            <Column className="datoproducto nombremarcacolumna" field="nombre" header="Nombre" sortable body={nombreClienteBodyTemplate}></Column>                            
                            <Column className="datoproducto editarmarcacolumna" field="url" header="Catálogo de marca" body={urlMarcaBodyTemplate}></Column>
                        </DataTable>
                    </div>    
                </div>
            </div>
        </div>
    );
}